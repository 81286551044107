import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../components/organisms";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import service from "../../../api/services";
import { HeaderHeading } from "../../../Context";

const { Brand } = organisms
const Index = () => {
    const url = window.location.href;
    const unrecognized = url.split('/').pop() || '';
    const { setHeader }: any = useContext(HeaderHeading);
    const [storeBrandList, setStoreBrandList] = useState()

    const getStoreBrandList = async () => {
        try {
            const response = await service.brandService?.brandList({ brand_category: unrecognized === 'unrecognized' ? 2 : 1 })
            if (response.status === 200) {
                setStoreBrandList(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        setHeader("Brand")
        getStoreBrandList()
    }, [unrecognized])

    return (
        <Wrapper>
            <Brand
                storeBrandList={storeBrandList}
                unrecognized={unrecognized}
            />
        </Wrapper>
    )
}

export default Index