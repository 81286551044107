import React, { useState, useEffect, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import service from '../../../../api/services'
import constant from "../../../../constants/constant"
import { Default_Page_Limit } from "../../../../constants/defaultValues"
import organisms from '../../../../components/organisms'
import { HeaderHeading } from '../../../../Context'

const { PendingInventory } = organisms

const Index = () => {
  const [initialLoading, setInitialLoading] = useState(false)
  const [transferRequestMoveOutList, setTransferRequestMoveOutList] = useState<any>();
  const [limitPerPage, setLimitPerPage] = useState<number>(Default_Page_Limit);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string | undefined>();
  const { setHeader }: any = useContext(HeaderHeading);


  const getTransferRequestMoveOutList = async () => {
    try {
      const response = await service.inventory.view({
        "page": currentPage,
        "search": search,
        "limit": limitPerPage,
        "status": constant.VERIFICATION_STATUS.PENDING,
      })
      if (response.status === 200) {
        setTransferRequestMoveOutList(response?.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setHeader("Inventory - Pending")
    if (initialLoading) {
      getTransferRequestMoveOutList()
    }
    setInitialLoading(true)
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(1)
    if (currentPage == 1) {
      getTransferRequestMoveOutList()
    }
  }, [limitPerPage,search])

  return (
    <Wrapper>
      <PendingInventory
        pendingInventoryData={transferRequestMoveOutList}
        setSearch={setSearch}
        limit={limitPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLimitPerPage={setLimitPerPage}
        updateVerificationStatus={(id:string, status:number)=>console.log("--->")}
      />
    </Wrapper>
  )
}

export default Index