import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { product } = Dictionary

const Product = {
    list: async (payload: {}, signal: any) => {
        return axios.post(BASE_URL + product.product(), payload, { signal: signal },)
    },
    filter: async () => {
        return axios.get(BASE_URL + product.filter())
    },
    productByID: async (id: string) => {
        return axios.get(BASE_URL + product.productById(id))
    },
    productTypeList: async (paramsObj: any) => {
        return axios.get(BASE_URL + product.productTypeList(), { params: paramsObj })
    },
    addToCart: async (data: {}) => {
        return axios.post(BASE_URL + product.addToCart(), data,)
    },

    //add product
    add: async (data: {}, headers: any) => {
        return axios.post(BASE_URL + product.addProduct(), data, { headers: headers })
    },
    delete: async (id: string) => {
        return axios.delete(BASE_URL + product.deleteProductById(id))
    },

    edit: async (id: string, data: {}, headers: any) => {
        return axios.put(BASE_URL + product.deleteProductById(id), data, {
            headers: headers,
        })
    },
    getDetailById: async (id: string) => {
        return axios.get(BASE_URL + product.productById(id))
    },
    deleteProductImages: async (payload: {}) => {
        return axios.post(BASE_URL + product.productImages(), payload)
    },
    productShapeListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + product.productShape(), { params: paramsObj })
    },
    productMaterialListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + product.productMaterial(), { params: paramsObj })
    },
    productColorListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + product.productColor(), { params: paramsObj })
    },
    brandListingService: async (paramsObj: any) => {
        return axios.get(BASE_URL + product.brand(), { params: paramsObj })
    },
    sizeDetails: async () => {
        return axios.get(BASE_URL + product.sizeDetails())
    },

    bulkProductUpload: async (payload: {}, headers: any) => {
        return axios.post(BASE_URL + product.bulkUpload(), payload, { headers: headers })
    },
}
export default Product
