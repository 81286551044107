import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import atoms from "../../atoms";
import constant from "../../../constants/constant";
import "./index.scss";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";
import { numberWithCommas } from "../../../constants/utils";
import CustomImages from "../../atoms/CustomImage";
import { ProductDetailsProps, SidePopUpProps } from "./interface";

const { Button, Image, Table } = atoms;
const SidePopUp = (props: SidePopUpProps) => {
    const [productDataTable, setProductDataTable] =
        useState<({} | ProductDetailsProps)[]>();

    const handleUpdateStatus = (status: number) => {
        props.updateVerificationStatus(props?.isViewData?.id, status);
        props.onHide();
    };

    const getNestedData = (data: ({} | ProductDetailsProps)[], type: string) => {
        let temp = 0;
        data?.forEach((value, index) => {
            if (type === "quantity") {
                temp += (value as ProductDetailsProps)[type];
            } else {
                temp += (value as any).product[type];
            }
        });
        return temp;
    };

    const totalSRP = (data: Array<any>): number => {
        return data.reduce((total: number, item: any) => {
            const productSRP = item?.product?.product_srp;
            if (typeof productSRP === "number" && !isNaN(productSRP)) {
                return total + productSRP;
            }
            return total;
        }, 0);
    };

    const columns = [
        {
            name: "Product ID",
            selector: (row: ProductDetailsProps) =>
                row?.product?.product_id_display || "",
            minWidth: "130px",
        },
        {
            name: "Product Type",
            selector: (row: ProductDetailsProps) =>
                !row?.product?.product_type ? (
                    <span className="fw-600 fs-14">Total</span>
                ) : (
                    row?.product?.product_type?.name
                ),
            sortable: true,
            minWidth: "110px",
        },
        {
            name: "Product Image",
            cell: (row: ProductDetailsProps) =>
                row?.product?.images?.length > 0 ? (
                    <CustomImages
                        src={row?.product?.images?.length > 0 ? row.product?.images[0] : ""}
                    />
                ) : (
                    ""
                ),
            center: true,
            minWidth: "120px",
        },
        {
            name: "Brand",
            selector: (row: ProductDetailsProps) =>
                row?.product?.brand?.brand_name || "",
            minWidth: "80px",
        },
        {
            name: "Model Number",
            selector: (row: ProductDetailsProps) => row?.product?.modal_number || "",
            minWidth: "90px",
        },
        {
            name: "Brand Color Code",
            selector: (row: ProductDetailsProps) =>
                row?.product?.brand_color_code || "",
            minWidth: "100px",
        },
        {
            name: "Glass Size",
            selector: (row: ProductDetailsProps) => row?.product?.glass_size || "",
            minWidth: "80px",
        },
        {
            name: "SRP (₹)",
            selector: (row: ProductDetailsProps) =>
                !row?.product ? (
                    <span className="fw-600 fs-14">
                        ₹
                        {numberWithCommas(
                            parseFloat(
                                totalSRP(props?.isViewData?.product_details)?.toFixed(2)
                            )
                        )}
                    </span>
                ) : row?.product?.product_srp ? (
                    numberWithCommas(parseFloat(row?.product.product_srp?.toFixed(2)))
                ) : (
                    ""
                ),
            minWidth: "90px",
        },
        {
            name: "QTY",
            selector: (row: ProductDetailsProps) =>
                !row?.product ? (
                    <span className="fw-600 fs-14">
                        {getNestedData(props.isViewData.product_details, "quantity")}
                    </span>
                ) : (
                    row?.quantity
                ),
            minWidth: "55px",
        },
        {
            name: "Amount",
            selector: (row: ProductDetailsProps) =>
                !row?.product ? (
                    <span className="fw-600 fs-14">
                        ₹
                        {numberWithCommas(
                            parseFloat(props.isViewData.total_amount?.toFixed(2))
                        )}
                    </span>
                ) : (
                    numberWithCommas(parseFloat(row.total_price?.toFixed(2)))
                ),
            minWidth: "90px",
        },
        {
            name: "Verification Status",
            selector: (row: ProductDetailsProps) =>
                row?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.PENDING ? (
                    <div className="background-light-yellow border-product-radius">
                        <p className="color-yellow m-1 p-1">{"Pending"}</p>
                    </div>
                ) : row?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.VERIFIED ? (
                    <div className="background-light-green border-product-radius">
                        <p className="color-green m-1 p-1">{"Approved"}</p>
                    </div>
                ) : row?.product?.verification_status ===
                    constant.VERIFICATION_STATUS.REWORK ? (
                    <div className="background-light-red border-product-radius">
                        <p className="color-red m-1 p-1">{"Rework"}</p>
                    </div>
                ) : (
                    ""
                ),
            minWidth: "120px",
        },
    ];

    useEffect(() => {
        if (props.isViewData?.product_details) {
            const tempProductData = [...props.isViewData?.product_details];
            if (tempProductData?.length > 0) {
                tempProductData.push({});
            }
            setProductDataTable(tempProductData);
        }
    }, [props.isViewData?.product_details]);

    return (
        <Modal
            className="rounded-0"
            isOpen={props.show}
            wrapClassName="modal-right"
        >
            <ModalHeader className="d-flex border-0 position-relative">
                <div className="mt-4 fs-24 fw-600 mb-0">Inventory</div>
                <div
                    className="position-absolute py-1 px-2"
                    style={{
                        top: "15%",
                        right: "3%",
                        backgroundColor: "transparent",
                        border: "1px solid #222",
                        borderRadius: "50%",
                        cursor: "pointer",
                    }}
                    onClick={props.onHide}
                >
                    <RxCross2 style={{ color: "#222" }} />
                </div>
            </ModalHeader>
            <ModalBody className="pt-md-0">
                <div className=" d-flex gap-3 align-items-center">
                    <h2 className="mb-0 fs-18 fw-700 secondary-color ">
                        Invoice Addition ID:
                    </h2>
                    <div className="d-flex justify-content-around align-items-center py-2 add-product-custom-div cursor-pointer">
                        <h2 className="mb-0 fs-16 fw-600 secondary-color">
                            {props?.isViewData?.invoice_id_display}
                        </h2>
                        <div className="">
                            <Image
                                src={"CopyIcon"}
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        props?.isViewData?.invoice_id_display
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <hr className="mt-3" style={{ border: "1px dashed #222" }} />

                <div className="row mt-4">
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        Physical Store
                    </div>
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        Supplier Invoice No
                    </div>
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        {" "}
                        Supplier Invoice Date
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData?.physical_store?.store_name}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData.supplier_invoice_number}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {moment(props.isViewData.supplier_invoice_date).format(
                            "DD-MM-YYYY"
                        )}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        Supplier Details
                    </div>
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        Created Date
                    </div>
                    <div className="col-4 fs-16 fw-800 secondary-color">
                        Actual Invoice Value
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData?.supplier?.supplier_name}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {moment(props.isViewData.createdAt).format("DD-MM-YYYY")}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData.actual_invoice}
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-4  fs-16 fw-800 secondary-color">
                        Computed Invoice Value
                    </div>
                    <div className="col-4  fs-16 fw-800 secondary-color">
                        Total Product Case{" "}
                    </div>
                    <div className="col-4  fs-16 fw-800 secondary-color">Total GST</div>
                </div>
                <div className="row ">
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData.total_amount}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData.total_product_case}
                    </div>
                    <div className="col-4 fs-16 fw-400 secondary-color">
                        {props.isViewData.total_gst}
                    </div>
                </div>

                <div>
                    {props.isViewData?.product_details ? (
                        <Table
                            columns={columns}
                            AdditionalClassName="table-height-50"
                            data={productDataTable}
                        />
                    ) : (
                        "No Data"
                    )}
                </div>
                <hr className="mt-5" style={{ border: "1px dashed #222" }} />
                <div className="row mt-4">
                    <div className="col-4 fs-16 fw-600 secondary-color">Remarks</div>
                </div>
                <div className="row mt-4 add-inventory-custom-div">
                    <div className="col-4 fs-16 fw-600 secondary-color">
                        {props?.isViewData?.remark}
                    </div>
                </div>
            </ModalBody>

            {props?.isViewData?.verification_status ==
                constant.VERIFICATION_STATUS.PENDING && (
                    <ModalFooter
                        className="border-0 d-flex"
                        style={{ flexWrap: "inherit" }}
                    >
                        <Button
                            className="fs-16 fw-500 reject-btn"
                            onClick={() =>
                                handleUpdateStatus(constant.VERIFICATION_STATUS.REWORK)
                            }
                        >
                            Reject
                        </Button>{" "}
                        <Button
                            className="fs-16 fw-500 approve-btn"
                            onClick={() =>
                                handleUpdateStatus(constant.VERIFICATION_STATUS.VERIFIED)
                            }
                        >
                            Approve
                        </Button>
                    </ModalFooter>
                )}
        </Modal>
    );
};

export default SidePopUp;
