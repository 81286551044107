import ConfirmationModal from './ConfirmationModal'
import CreateModal from './CreateModal'
import SidePopUp from './Sidepopup'
import SearchTab from './SearchTab'
import CheckboxDropdown from './CheckboxDropdown'
import LightBox from './LightBox'
import CarouselComp from './Carousel'
import TopBar from './TopBar'
import PurchaseCard from './PurchaseCard'
import BillingSummary from './BillingSummary'
import ProductCard from './ProductCard'
import Filter from './Filter'
import PriceRange from './PriceRange'
import BulkSidePopUp from './BulkUpload/bulkUploadLightBox'
import CustomTable from './CustomTable'

const molecules = {
    ConfirmationModal,
    CreateModal,
    SidePopUp,
    SearchTab,
    CheckboxDropdown,
    LightBox,
    CarouselComp,
    TopBar,
    PurchaseCard,
    BillingSummary,
    ProductCard,
    Filter,
    PriceRange,
    BulkSidePopUp,
    CustomTable,
}
export default molecules