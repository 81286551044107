import React, { useState } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import organisms from '../../../../components/organisms'
import service from '../../../../api/services'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
const { AddBrand } = organisms
const Index = () => {
    const navigate = useNavigate()
    const { addToast } = useToasts();
    const [brandData, setBrandData] = useState({
        brand_logo: '',
        brand_name: "",
        brand_code: "",
        owner_name: "",
    })

    const addBrand = async () => {

        const payload = {
            brand_type: 2,
            brand_logo: brandData?.brand_logo,
            brand_name: brandData?.brand_name,
            brand_code: brandData?.brand_code,
            owner_name: "",
        }

        try {
            const response = await service.brandService.AddBrand(payload)
            if (response?.status === 201) {
                addToast('Brand Create Successfully', {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }else{
                addToast('The brand name already exists.', {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
            }

        } catch (error: any) {
            console.log(error)
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    return (
        <Wrapper>
            <AddBrand
                brandData={brandData}
                setBrandData={setBrandData}
                setCreateClick={() => addBrand()}
            />
        </Wrapper>
    )
}

export default Index